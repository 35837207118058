import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Title", {
        attrs: {
          pages: [
            { icon: "file-invoice-dollar", page: _vm.page_title.toUpperCase() },
          ],
        },
      }),
      _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _vm.create_access
            ? _c("Modal", {
                attrs: {
                  button_name: _vm.$t("Crear") + " " + _vm.modal_title,
                  modal_title: _vm.modal_title,
                  formBtn: _vm.formBtn,
                  dialog: _vm.dialog,
                  service: _vm.service,
                  item: _vm.mainItem,
                },
                on: {
                  updateDialog: _vm.updateDialog,
                  loadList: function ($event) {
                    return _vm.loadList(_vm.service)
                  },
                  updateItem: function (newItem) {
                    _vm.mainItem = newItem
                  },
                },
              })
            : _vm._e(),
          _c(VCard, [
            _c("div", { staticClass: "row mb-5 mt-0" }, [
              _vm.list_access
                ? _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-md-12",
                        staticStyle: { background: "white" },
                      },
                      [
                        _c("div", { staticClass: "row pt-5" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(
                                VMenu,
                                {
                                  ref: "desde_selected",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "close-on-click": false,
                                    "return-value": _vm.desde_selected,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                    clearable: "",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.desde_selected = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.desde_selected = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VTextField,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "pt-0 mt-0",
                                                    attrs: {
                                                      label: "Desde",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                    },
                                                    model: {
                                                      value: _vm.desde_selected,
                                                      callback: function ($$v) {
                                                        _vm.desde_selected = $$v
                                                      },
                                                      expression:
                                                        "desde_selected",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2292088029
                                  ),
                                  model: {
                                    value: _vm.desde_selected_open,
                                    callback: function ($$v) {
                                      _vm.desde_selected_open = $$v
                                    },
                                    expression: "desde_selected_open",
                                  },
                                },
                                [
                                  _c(
                                    VDatePicker,
                                    {
                                      attrs: {
                                        type: "date",
                                        "no-title": "",
                                        scrollable: "",
                                      },
                                      model: {
                                        value: _vm.desde_selected,
                                        callback: function ($$v) {
                                          _vm.desde_selected = $$v
                                        },
                                        expression: "desde_selected",
                                      },
                                    },
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.desde_selected = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.desde_selected.save(
                                                _vm.desde_selected
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(
                                VMenu,
                                {
                                  ref: "hasta_selected",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "close-on-click": false,
                                    "return-value": _vm.hasta_selected,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "auto",
                                    clearable: "",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.hasta_selected = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.hasta_selected = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              VTextField,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "pt-0 mt-0",
                                                    attrs: {
                                                      label: "Hasta",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: "",
                                                    },
                                                    model: {
                                                      value: _vm.hasta_selected,
                                                      callback: function ($$v) {
                                                        _vm.hasta_selected = $$v
                                                      },
                                                      expression:
                                                        "hasta_selected",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1999352541
                                  ),
                                  model: {
                                    value: _vm.hasta_selected_open,
                                    callback: function ($$v) {
                                      _vm.hasta_selected_open = $$v
                                    },
                                    expression: "hasta_selected_open",
                                  },
                                },
                                [
                                  _c(
                                    VDatePicker,
                                    {
                                      attrs: {
                                        type: "date",
                                        "no-title": "",
                                        scrollable: "",
                                      },
                                      model: {
                                        value: _vm.hasta_selected,
                                        callback: function ($$v) {
                                          _vm.hasta_selected = $$v
                                        },
                                        expression: "hasta_selected",
                                      },
                                    },
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.hasta_selected = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.hasta_selected.save(
                                                _vm.hasta_selected
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VTextField, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  clearable: "",
                                  autocomplete: "off",
                                  label: "Nº Orden",
                                },
                                model: {
                                  value: _vm.nro_orden_selected,
                                  callback: function ($$v) {
                                    _vm.nro_orden_selected = $$v
                                  },
                                  expression: "nro_orden_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VTextField, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  clearable: "",
                                  autocomplete: "off",
                                  label: "Nº Remito",
                                },
                                model: {
                                  value: _vm.nro_remito_selected,
                                  callback: function ($$v) {
                                    _vm.nro_remito_selected = $$v
                                  },
                                  expression: "nro_remito_selected",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "state_selected",
                                  name: "state_selected",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.states,
                                  label: "Estado",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.state_selected,
                                  callback: function ($$v) {
                                    _vm.state_selected = $$v
                                  },
                                  expression: "state_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "client_selected",
                                  name: "client_selected",
                                  "item-text": "business_name",
                                  "item-value": "id",
                                  items: _vm.clients,
                                  label: "Cliente",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.clientChangeCombo($event)
                                  },
                                },
                                model: {
                                  value: _vm.client_selected,
                                  callback: function ($$v) {
                                    _vm.client_selected = $$v
                                  },
                                  expression: "client_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 pt-0" },
                            [
                              _c(VCombobox, {
                                staticClass: "pt-0 mt-0",
                                attrs: {
                                  autocomplete: "off",
                                  "data-vv-as": "applicant_selected",
                                  name: "applicant_selected",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.applicants,
                                  label: "Solicitante",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.applicant_selected,
                                  callback: function ($$v) {
                                    _vm.applicant_selected = $$v
                                  },
                                  expression: "applicant_selected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "col-md-3 pt-0",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "end",
                              },
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "mr-2 text-white",
                                  attrs: { color: "blue-grey" },
                                  on: { click: _vm.exportToExcel },
                                },
                                [_vm._v("Exportar")]
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { color: "info" },
                                  on: { click: _vm.filter },
                                },
                                [_vm._v("Buscar")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.list_access
            ? _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    [
                      _c("span", { staticClass: "text-uppercase" }, [
                        _vm._v(_vm._s(_vm.page_title)),
                      ]),
                      _c(VSpacer),
                      _c(VTextField, {
                        attrs: {
                          "append-icon": "search",
                          label: "Buscar",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.selected.length != 0
                    ? _c(
                        "div",
                        {
                          staticClass: "col-md-12",
                          staticStyle: { background: "#f4f1f1" },
                        },
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              staticClass: "cursor mb-1 mt-1",
                                              attrs: { color: "green" },
                                              on: {
                                                click: _vm.generateInvoiceModal,
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("post_add")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2980845440
                              ),
                            },
                            [_c("span", [_vm._v("Generar facturas")])]
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              staticClass:
                                                "cursor mb-1 mt-1 ml-5",
                                              attrs: { color: "blue" },
                                              on: {
                                                click: _vm.downloadRemitos,
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("download")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1828102928
                              ),
                            },
                            [_c("span", [_vm._v("Descargar remitos")])]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      search: _vm.search,
                      loading: _vm.loading,
                      "loading-text": "Cargando...",
                      options: {
                        descending: true,
                        itemsPerPage: 15,
                      },
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 15, 50, -1],
                      },
                      "single-expand": false,
                      expanded: _vm.expanded,
                      "show-expand": "",
                      "show-select": "",
                      "single-select": false,
                    },
                    on: {
                      pagination: _vm.updatePagination,
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (ref) {
                            var item = ref.item
                            var expand = ref.expand
                            var isExpanded = ref.isExpanded
                            var isSelected = ref.isSelected
                            var select = ref.select
                            return [
                              _c("tr", [
                                _c(
                                  "td",
                                  [
                                    !isExpanded
                                      ? _c("font-awesome-icon", {
                                          staticClass: "cursor",
                                          attrs: { icon: "chevron-down" },
                                          on: {
                                            click: function ($event) {
                                              return expand(!isExpanded)
                                            },
                                          },
                                        })
                                      : _c("font-awesome-icon", {
                                          staticClass: "cursor",
                                          attrs: { icon: "chevron-up" },
                                          on: {
                                            click: function ($event) {
                                              return expand(!isExpanded)
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(VCheckbox, {
                                      staticStyle: {
                                        margin: "0px",
                                        padding: "0px",
                                      },
                                      attrs: {
                                        "input-value": isSelected,
                                        "hide-details": "",
                                        disabled:
                                          item.state.id ==
                                          _vm.remitosWS.STATE_BILLED,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return select($event)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(item.id.toString().padStart(7, "0"))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      item.work_order.id
                                        .toString()
                                        .padStart(7, "0")
                                    )
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(item.creation_date))]),
                                _c("td", [
                                  _vm._v(_vm._s(item.client.business_name)),
                                ]),
                                _c("td", {
                                  staticStyle: { "min-width": "135px" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.service.getStateHtml(item.state)
                                    ),
                                  },
                                }),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUtils.formatPrice(item.total)
                                    )
                                  ),
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "justify-content-center px-0",
                                  },
                                  [
                                    item.state.id != _vm.remitosWS.STATE_BILLED
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.update_access
                                                        ? _c(
                                                            VIcon,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                  color: "blue",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v(" edit ")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Editar")])]
                                        )
                                      : _vm._e(),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    VIcon,
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "blue",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.viewItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v(" remove_red_eye ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Ver")])]
                                    ),
                                    _vm.sensible_access
                                      ? _c(
                                          VMenu,
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                                color: "orange",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" print ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              VList,
                                              [
                                                _c(
                                                  VListItem,
                                                  [
                                                    _c(
                                                      VListItemTitle,
                                                      {
                                                        staticClass: "cursor",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.printCocepts(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Imprimir remito"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VListItem,
                                                  [
                                                    _c(
                                                      VListItemTitle,
                                                      {
                                                        staticClass: "cursor",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.printValues(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Imprimir remito con precios"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        VIcon,
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                                color: "orange",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.printCocepts(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-icon",
                                                            _vm.attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" print ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Imprimir")])]
                                        ),
                                    item.state.id != _vm.remitosWS.STATE_BILLED
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.delete_access
                                                        ? _c(
                                                            VIcon,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                  color: "red",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v(" delete ")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Anular")])]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function (ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c(VDataTable, {
                                    staticClass:
                                      "background-color: grey lighten-3",
                                    attrs: {
                                      headers: _vm.headersTasks,
                                      items: item.items,
                                      search: _vm.search,
                                      "loading-text": "Cargando",
                                      "hide-default-footer": "",
                                      "disable-pagination": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function (props) {
                                            return [
                                              _c("tr", [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(props.index + 1)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.asignated_task
                                                        .task.name
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item.spent
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item
                                                            .spent_writing_right
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item
                                                            .spent_notarial_contribution
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item.honorary
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item
                                                            .legalizacion
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item.apostilla
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item
                                                            .apostilla_urgente
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item.anexo_spent
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item
                                                            .anexo_honorary
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatUtils.formatPrice(
                                                          props.item.total
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                VAlert,
                                {
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        "Busqueda sin resultados - " +
                                          _vm.search
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1278936813
                    ),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("DialogDelete", {
            attrs: { dialog_delete: _vm.dialog_delete },
            on: {
              updateDialogDelete: _vm.updateDialogDelete,
              deleteItemConfirm: _vm.deleteItemConfirm,
            },
          }),
          _c("DialogLoading", {
            attrs: {
              dialog: _vm.dialog_loading && !_vm.loading_massive,
              text: "Cargando...",
            },
          }),
          _c("DialogGenerarFactura", {
            attrs: {
              title: "Generar Factura",
              remitos: _vm.selected,
              open: _vm.open_generate_factura_massive,
            },
            on: {
              cancel: function ($event) {
                _vm.open_generate_factura_massive = false
              },
              accept: _vm.generateInvoice,
              loadList: _vm.filter,
            },
          }),
          _c(
            VLayout,
            { staticClass: "pull-left" },
            [
              _c(
                VDialog,
                {
                  attrs: { "max-width": "400px" },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      ) {
                        return null
                      }
                      _vm.loading_massive = false
                    },
                  },
                  model: {
                    value: _vm.loading_massive,
                    callback: function ($$v) {
                      _vm.loading_massive = $$v
                    },
                    expression: "loading_massive",
                  },
                },
                [
                  _c(
                    VCard,
                    [
                      _c(
                        VForm,
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.imprimir.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(VProgressLinear, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loading_massive,
                                expression: "!loading_massive",
                              },
                            ],
                            attrs: {
                              height: 20,
                              indeterminate: "",
                              color: "green darken-2",
                            },
                          }),
                          _c(VCardText, [
                            _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-12 subtitle-1 text-center",
                                  },
                                  [_vm._v(" Exportando... ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-md-12",
                                    attrs: { id: "progress_bar" },
                                  },
                                  [
                                    _c(
                                      VProgressLinear,
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading_massive,
                                            expression: "loading_massive",
                                          },
                                        ],
                                        attrs: {
                                          height: 25,
                                          color: "green darken-2",
                                        },
                                        model: {
                                          value: _vm.loading_percentage,
                                          callback: function ($$v) {
                                            _vm.loading_percentage = $$v
                                          },
                                          expression: "loading_percentage",
                                        },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              Math.ceil(_vm.loading_percentage)
                                            ) + "%"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-layout>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">


            <v-card>
                <v-card-title>
                    <span class="headline">Libro #{{ libro_id }}</span>
                </v-card-title>
                
                    <v-card class="mb-0 mt-3 ml-3 mr-3" style="elevation-0">
                        <!-- <div class="row mb-5 mt-5 ml-2 mr-2"> -->
                                <div class="col-md-12" style="background:white; elevation-0">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <v-combobox v-model="signatory_selected" autocomplete="off"
                                                data-vv-as="signatory_selected" name="signatory_selected"
                                                item-text="name" item-value="id" :items="signatories" label="Firmante"
                                                class="pt-0 mt-3" clearable></v-combobox>
                                        </div>
                                        <div class="col-md-3">
                                            <v-combobox v-model="state_selected" autocomplete="off" data-vv-as="state_selected"
                                                name="state_selected" item-text="name" item-value="id" :items="states"
                                                label="Estado" class="pt-0 mt-3" clearable></v-combobox>
                                        </div>
                                     
                                        <div class="col-md-6 mt-4" style="display: flex; justify-content: end">
                                            <v-btn color="blue-grey" class="mr-2 text-white"
                                                @click="exportToExcel">Exportar</v-btn>
                                            <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                                        </div>
                                    </div>
                            </div>
                        <!-- </div> -->
                    </v-card>   
                
                <v-card-text>
                    <v-data-table v-model="selected" :headers="headers" :loading="loading_libro_evento"
                        :items="libros_eventos" loading-text="Cargando..." show-select :items-per-page="15">
                        <template v-slot:item="{ item, isSelected, select }">
                            <tr>
                                <td>
                                    <v-checkbox v-if="item.state_libro_evento.id != libroService.STATE_LIBRO_EVENTO_SIGNED" :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details
                                        @change="select($event)" />
                                </td>
                                <td>{{ item.order }}</td>
                                <td>{{ item.signatory }}</td>
                                <td>{{ item.client }}</td>
                                <td>{{ item.fecha }}</td>
                                <td>{{ item.acta }}</td>
                                <td>
                                    {{ item.state_libro_evento != null ? item.state_libro_evento.name : "-" }}
                                </td>

                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="close">Cerrar</v-btn>
                    <v-btn color="success" text @click="signedEvent" :loading="loading_signed_libro_evento">Firmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import { libroService } from '../../libs/ws/libroService';
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/sections";


export default {
    props: {
        dialog: Boolean,
        libro_id: Number,
        notary_id: Number,

    },
    data() {
        return {
            libroService: libroService,
            headers: [
                // { text: "", align: "center", sortable: false, value: "" },

                {
                    text: "Orden",
                    align: "left",
                    sortable: true,
                    value: "order"
                },
                {
                    text: "Firmante",
                    align: "left",
                    sortable: true,
                    value: "signatory"
                },
                {
                    text: "Cliente",
                    align: "left",
                    sortable: true,
                    value: "client"
                },
                {
                    text: "Fecha",
                    align: "left",
                    sortable: true,
                    value: "fecha"
                },
                {
                    text: "Acta",
                    align: "left",
                    sortable: true,
                    value: "acta"
                },
                {
                    text: "Estado",
                    align: "left",
                    sortable: true,
                    value: "state_libro_evento"
                },
               
            ],
            libros_eventos: [],
            loading_libro_evento: false,
            selected: [],
            states: [],
            signatory_selected: null,
            state_selected: null,
            signatories: [],
            loading_signed_libro_evento: false
        };
    },
    watch: {
        dialog(newVal) {
            if (newVal) {
                this.fetchLibroEvento();
            }
        }
    },
    mounted() {
        this.loadSignatories();
        this.loadStates();
    },
    methods: {
        loadStates() {
            libroService
                .states_libro_evento()
                .then(response => {
                    this.states = response.result;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadSignatories() {
            generalService
                .filter({}, Constant.SECTION_SIGNATORIES)
                .then((response) => {

                    this.signatories = response.result;

                })
                .catch((e) => {
                    console.log("loadSignatories error");
                    console.log(e);
                });
        },
        close() {
            this.$emit("closeModal");
            this.libros_eventos = [];
            this.selected = [];
            this.signatory_selected = null; 
            this.state_selected = null;
        },
        signedEvent() {
            this.loading_signed_libro_evento = true;
            this.selected = this.selected.filter(item => item.state_libro_evento.id != libroService.STATE_LIBRO_EVENTO_SIGNED);

            const params = {
                libro_id: this.libro_id,
                libro_eventos: this.selected
            };
            
            libroService
                .signed_libro_evento(params)
                .then(response => {
                    if (response.status) {
                        this.close();
                        this.$toast.success(response.msg, "Exito");
                    } else {
                        this.$toast.error(response.msg, "Error");
                    }
                }).catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading_signed_libro_evento = false;
                });
        },
        filter() {
            this.fetchLibroEvento();
        },
        getFilterParams() {
            let param = {
                libro_id: this.libro_id,
                notary_id: this.notary_id,
                signatory_id: (this.signatory_selected != null) ? this.signatory_selected.id : null,
                state_libro_evento: (this.state_selected != null) ? this.state_selected.id : null,
            };

            return param;
        },
        fetchLibroEvento() {
            this.loading_libro_evento = true;

            const params = this.getFilterParams();

            libroService
                .get_libro_evento(params)
                .then(response => {
                    this.libros_eventos = response.result;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading_libro_evento = false;
                });
        },
        exportToExcel() {
            if (this.loading_libro_evento) return;
            this.loading_libro_evento = true;
            let param = this.getFilterParams();

            libroService
                .export_filter(param)
                .then((response) => {
                if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
                    this.$toast.error(response.msg, "Error");
                    this.loading_libro_evento = false;
                    return;
                }

                let blob = new Blob([response], {
                    type: "application/vnd.ms-excel",
                });
                let link = document.createElement("a");
                //link.setAttribute('download', "export")
                link.download = "libro-evento.xlsx";
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                link.click();
                this.loading_libro_evento = false;
                })
                .catch((e) => {
                console.log("service.export_filter error");
                console.log(e);
                this.loading_libro_evento = false;
                });
        },
    }
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VDataTable, {
        attrs: {
          headers: _vm.headers,
          items: _vm.list,
          loading: _vm.loading,
          "loading-text": "Cargando...",
          "hide-default-footer": "",
          "items-per-page": 50,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(props.item.documentation_type.name)),
                  ]),
                  _c(
                    "td",
                    [
                      _c(VCheckbox, {
                        staticClass: "pa-0",
                        attrs: { disabled: _vm.isViewModal, small: "" },
                        model: {
                          value: props.item.apply,
                          callback: function ($$v) {
                            _vm.$set(props.item, "apply", $$v)
                          },
                          expression: "props.item.apply",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(props.item.requested_date))]),
                  _c("td", [_vm._v(_vm._s(props.item.received_date))]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      !_vm.isViewModal
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                small: "",
                                                color: "blue",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editItem(
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("edit")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Editar")])]
                          )
                        : _vm._e(),
                      props.item.property_documentation_files.length > 0
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                small: "",
                                                color: "blue",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewAttachItem(
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v(" remove_red_eye ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Ver")])]
                          )
                        : _vm._e(),
                      !_vm.isViewModal
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VIcon,
                                          _vm._g(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                small: "",
                                                color: "orange",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.attachItem(
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("attach_file")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Adjuntar")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        VDialog,
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog_edit,
            callback: function ($$v) {
              _vm.dialog_edit = $$v
            },
            expression: "dialog_edit",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(_vm._s(_vm.editedItem.documentation_type.name)),
                ]),
              ]),
              _c(VCardText, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(VCheckbox, {
                        attrs: { label: "Aplica" },
                        model: {
                          value: _vm.editedItem.apply,
                          callback: function ($$v) {
                            _vm.$set(_vm.editedItem, "apply", $$v)
                          },
                          expression: "editedItem.apply",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("DatePickerEsp", {
                        staticClass: "pt-0",
                        attrs: {
                          label: "Fecha Pedido",
                          date: _vm.editedItem.requested_date,
                          initToday: true,
                          name: "requested_date",
                        },
                        on: {
                          setDate: function (date) {
                            return (_vm.editedItem.requested_date = date)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("DatePickerEsp", {
                        staticClass: "pt-0",
                        attrs: {
                          label: "Fecha Recibido",
                          date: _vm.editedItem.received_date,
                          name: "received_date",
                        },
                        on: {
                          setDate: function (date) {
                            return (_vm.editedItem.received_date = date)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                VCardActions,
                { staticClass: "justify-end" },
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.closeEdit },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "success", text: "" },
                      on: { click: _vm.saveEdit },
                    },
                    [_vm._v("Guardar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog_attach,
            callback: function ($$v) {
              _vm.dialog_attach = $$v
            },
            expression: "dialog_attach",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(
                    _vm._s(_vm.view_attach ? "Ver Documentos" : "Documentos")
                  ),
                ]),
              ]),
              _c(VCardText, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12 mb-4" },
                    [
                      !_vm.view_attach
                        ? _c(
                            "div",
                            { staticClass: "mt-2 mb-2" },
                            [
                              _c("input", {
                                ref: "fileInput",
                                staticStyle: { display: "none" },
                                attrs: { type: "file", multiple: "" },
                                on: { change: _vm.handleFileSelect },
                              }),
                              _c(
                                VBtn,
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.fileInput.click()
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { left: "" } }, [
                                    _vm._v("mdi-upload"),
                                  ]),
                                  _vm._v(" Adjuntar Documentos "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(VDataTable, {
                        attrs: {
                          headers: !_vm.view_attach
                            ? _vm.headers_documents
                            : _vm.headers_documents_view,
                          items: _vm.editedItem.property_documentation_files,
                          loading: _vm.loading,
                          "loading-text": "Cargando...",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (props) {
                              return [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      class:
                                        props.item.id > 0 ? "redirect" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.download(props.item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(props.item.name))]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "justify-content-center layout px-0",
                                    },
                                    [
                                      !_vm.view_attach
                                        ? _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          VIcon,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                                color: "red",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeFile(
                                                                      props.index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("mdi-delete")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Eliminar")])]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                VCardActions,
                { staticClass: "justify-end" },
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.closeEdit },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  !_vm.view_attach
                    ? _c(
                        VBtn,
                        {
                          attrs: { color: "success", text: "" },
                          on: { click: _vm.saveEdit },
                        },
                        [_vm._v("Adjuntar")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    [
      _c(
        VDialog,
        {
          attrs: {
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Libro #" + _vm._s(_vm.libro_id)),
                ]),
              ]),
              _c(
                VCard,
                { staticClass: "mb-0 mt-3 ml-3 mr-3", staticStyle: {} },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-12",
                      staticStyle: { background: "white" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(VCombobox, {
                              staticClass: "pt-0 mt-3",
                              attrs: {
                                autocomplete: "off",
                                "data-vv-as": "signatory_selected",
                                name: "signatory_selected",
                                "item-text": "name",
                                "item-value": "id",
                                items: _vm.signatories,
                                label: "Firmante",
                                clearable: "",
                              },
                              model: {
                                value: _vm.signatory_selected,
                                callback: function ($$v) {
                                  _vm.signatory_selected = $$v
                                },
                                expression: "signatory_selected",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(VCombobox, {
                              staticClass: "pt-0 mt-3",
                              attrs: {
                                autocomplete: "off",
                                "data-vv-as": "state_selected",
                                name: "state_selected",
                                "item-text": "name",
                                "item-value": "id",
                                items: _vm.states,
                                label: "Estado",
                                clearable: "",
                              },
                              model: {
                                value: _vm.state_selected,
                                callback: function ($$v) {
                                  _vm.state_selected = $$v
                                },
                                expression: "state_selected",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-md-6 mt-4",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "end",
                            },
                          },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "mr-2 text-white",
                                attrs: { color: "blue-grey" },
                                on: { click: _vm.exportToExcel },
                              },
                              [_vm._v("Exportar")]
                            ),
                            _c(
                              VBtn,
                              {
                                attrs: { color: "info" },
                                on: { click: _vm.filter },
                              },
                              [_vm._v("Buscar")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                VCardText,
                [
                  _c(VDataTable, {
                    attrs: {
                      headers: _vm.headers,
                      loading: _vm.loading_libro_evento,
                      items: _vm.libros_eventos,
                      "loading-text": "Cargando...",
                      "show-select": "",
                      "items-per-page": 15,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (ref) {
                          var item = ref.item
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c("tr", [
                              _c(
                                "td",
                                [
                                  item.state_libro_evento.id !=
                                  _vm.libroService.STATE_LIBRO_EVENTO_SIGNED
                                    ? _c(VCheckbox, {
                                        staticStyle: {
                                          margin: "0px",
                                          padding: "0px",
                                        },
                                        attrs: {
                                          "input-value": isSelected,
                                          "hide-details": "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return select($event)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(item.order))]),
                              _c("td", [_vm._v(_vm._s(item.signatory))]),
                              _c("td", [_vm._v(_vm._s(item.client))]),
                              _c("td", [_vm._v(_vm._s(item.fecha))]),
                              _c("td", [_vm._v(_vm._s(item.acta))]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.state_libro_evento != null
                                        ? item.state_libro_evento.name
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "error", text: "" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("Cerrar")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "success",
                        text: "",
                        loading: _vm.loading_signed_libro_evento,
                      },
                      on: { click: _vm.signedEvent },
                    },
                    [_vm._v("Firmar")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
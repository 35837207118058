import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    [
      _c("ModalTask", {
        attrs: {
          dialog: _vm.dialog_task,
          item: _vm.item,
          client: _vm.item.client,
          asignatedTaskItemEdit: _vm.asignatedTaskItemEdit,
          editedIndex: _vm.editedIndex,
          viewdIndex: _vm.viewdIndex,
        },
        on: {
          editAssignatedTasks: _vm.editAssignatedTasks,
          closeDialog: _vm.closeModalTask,
        },
      }),
      _c(
        VDialog,
        {
          attrs: {
            value: _vm.dialog,
            transition: "dialog-bottom-transition",
            fullscreen: "",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.$emit("updateDialog", false)
            },
            "click:outside": function ($event) {
              return _vm.$emit("updateDialog", false)
            },
            input: function ($event) {
              return _vm.$emit("updateDialog", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    VBtn,
                    _vm._g(
                      {
                        staticClass: "mb-5",
                        attrs: { color: "primary", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("updateDialog", true)
                          },
                        },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.button_name))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                {
                  ref: "modal_form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(VCardTitle, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.modal_title)),
                    ]),
                  ]),
                  _c(
                    VCardText,
                    { staticClass: "container mt-5 card-text-body" },
                    [
                      _vm.item.id <= 0
                        ? _c("p", { staticClass: "text-h6 mb-5" }, [
                            _vm._v(
                              "Complete el formulario para crear una orden"
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  { attrs: { md: "3" } },
                                  [
                                    _c(VCombobox, {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        "data-vv-as": "cliente",
                                        name: "client",
                                        "item-text": "business_name",
                                        "item-value": "id",
                                        items: _vm.clients,
                                        label: "Cliente*",
                                        autocomplete: "off",
                                        disabled:
                                          _vm.isViewModal() ||
                                          _vm.item.asignated_tasks.length > 0,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.clientChangeCombo($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.item.client,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "client", $$v)
                                        },
                                        expression: "item.client",
                                      },
                                    }),
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("client"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { md: "3" } },
                                  [
                                    _c(VCombobox, {
                                      attrs: {
                                        "data-vv-as": "solicitante",
                                        name: "applicant",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.applicants,
                                        label: "Solicitante",
                                        autocomplete: "off",
                                        disabled: _vm.isViewModal(),
                                      },
                                      model: {
                                        value: _vm.item.applicant,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "applicant", $$v)
                                        },
                                        expression: "item.applicant",
                                      },
                                    }),
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("applicant"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(/*ONLY EMPLOYEES*/) + " "),
                                _c(
                                  VCol,
                                  { attrs: { md: "3" } },
                                  [
                                    _c(VCombobox, {
                                      attrs: {
                                        "data-vv-as": "employee",
                                        name: "employee",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.employees,
                                        label: "Ejecutante",
                                        autocomplete: "off",
                                        disabled: _vm.isViewModal(),
                                      },
                                      model: {
                                        value: _vm.item.employee,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "employee", $$v)
                                        },
                                        expression: "item.employee",
                                      },
                                    }),
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("employee"))
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(/*ONLY EMPLOYEES*/) + " "),
                                _c(
                                  VCol,
                                  { attrs: { md: "3" } },
                                  [
                                    _c("DatePickerEsp", {
                                      attrs: {
                                        label: "Fecha expiración",
                                        initToday: true,
                                        date: _vm.item.expiration_date,
                                        isRequired: true,
                                        name: "expiration_date",
                                        isViewModal: _vm.isViewModal(),
                                      },
                                      on: {
                                        setDate: function (date) {
                                          _vm.item.expiration_date = date
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              VRow,
                              [
                                _vm._v(" " + _vm._s(/*ONLY EMPLOYEES*/) + " "),
                                _c(
                                  VCol,
                                  { attrs: { md: "6" } },
                                  [
                                    _c(VTextarea, {
                                      attrs: {
                                        outlined: "",
                                        name: "observaciones_internas",
                                        label: "Detalle de factura",
                                      },
                                      model: {
                                        value: _vm.item.observaciones_internas,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "observaciones_internas",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.observaciones_internas",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  VCol,
                                  { attrs: { md: "6" } },
                                  [
                                    _c(VTextarea, {
                                      attrs: {
                                        outlined: "",
                                        name: "observaciones_cliente",
                                        label: "Observaciones Cliente",
                                      },
                                      model: {
                                        value: _vm.item.observaciones_cliente,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "observaciones_cliente",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.observaciones_cliente",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  [
                                    !_vm.isViewModal()
                                      ? _c(
                                          VBtn,
                                          {
                                            staticClass: "mb-3",
                                            attrs: { color: "info" },
                                            on: { click: _vm.openModalTask },
                                          },
                                          [_vm._v("+ Agregar tarea")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(VDataTable, {
                              staticStyle: { border: "solid 1px #1c1c1c" },
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.item.asignated_tasks,
                                "hide-default-footer": true,
                                "items-per-page": 100,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(props.index + 1)),
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(props.item.task.name)),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(props.item.details.length)
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { key: props.item.documents.length },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.item.documents.length
                                              )
                                            ),
                                          ]
                                        ),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.signatories.length
                                            )
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getPriorityName(
                                                props.item.priority
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              VTooltip,
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            VIcon,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  small: "",
                                                                  color: "blue",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.viewItem(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " remove_red_eye "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Ver")])]
                                            ),
                                            !_vm.isViewModal()
                                              ? _c(
                                                  VTooltip,
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                VIcon,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "blue",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.editItem(
                                                                            props.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " edit "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Editar"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !_vm.isViewModal()
                                              ? _c(
                                                  VTooltip,
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                VIcon,
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "red",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeItem(
                                                                            props.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " indeterminate_check_box "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Remover"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "header",
                                  fn: function (props) {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { colspan: "1" } },
                                            [_vm._v("Numero")]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { colspan: "1" } },
                                            [_vm._v("Tarea")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-center",
                                              attrs: { colspan: "3" },
                                            },
                                            [_vm._v("Informacion adicional")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-center",
                                              attrs: { colspan: "1" },
                                            },
                                            [_vm._v("Prioridad")]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "text-center",
                                              attrs: { colspan: "1" },
                                            },
                                            [_vm._v("Accion")]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    VCardActions,
                    { staticClass: "card-footer-fullscreen" },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("updateDialog", false)
                            },
                          },
                        },
                        [_vm._v("Cerrar")]
                      ),
                      !_vm.isViewModal()
                        ? _c(
                            VBtn,
                            {
                              attrs: {
                                color: "success",
                                "min-width": "100",
                                loading: _vm.loading,
                                type: "submit",
                              },
                            },
                            [_vm._v(_vm._s(_vm.formBtn))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { wsService } from "./wsService";

const section = "libros";

const STATE_LIBRO_EVENTO_SIGNED = 1;
const STATE_LIBRO_EVENTO_NOT_SIGNED = 2;

function get_libro_evento(param = { enable: true }) {
  return wsService.make_post_request(section + "/get-libro-evento", param);
}

function states_libro_evento(param = { enable: true }) {
  return wsService.make_post_request(section + "/states-libro-evento", param);
}

function signed_libro_evento(param = { enable: true }) {
  return wsService.make_post_request(section + "/signed-libro-evento", param);
}

function next_foja(param = {enable: true}) {
  return wsService.make_post_request(section + "/next-foja", param);
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export-libro-evento", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

export const libroService = {
  section,
  STATE_LIBRO_EVENTO_SIGNED,
  STATE_LIBRO_EVENTO_NOT_SIGNED,
  get_libro_evento,
  states_libro_evento,
  signed_libro_evento,
  next_foja,
  export_filter
};

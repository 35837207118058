import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ModalQuickLoad", {
        attrs: { dialog: _vm.dialog_quick_load },
        on: {
          closeDialog: function ($event) {
            _vm.dialog_quick_load = false
          },
          processQuickLoad: _vm.processQuickLoad,
        },
      }),
      _c("ModalAddSignatory", {
        attrs: {
          dialog: _vm.dialog_add_signatory,
          item: _vm.asignatedTaskItem,
          client: _vm.client,
        },
        on: {
          closeDialog: function ($event) {
            _vm.dialog_add_signatory = false
          },
        },
      }),
      _c(
        VDialog,
        {
          attrs: { transition: "dialog-bottom-transition", "max-width": "800" },
          on: {
            "click:outside": function ($event) {
              return _vm.closeModalTask()
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModalTask()
            },
            input: function ($event) {
              return _vm.closeModalTask()
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            VForm,
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addTask.apply(null, arguments)
                },
              },
            },
            [
              _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "headline" }, [
                    _vm._v("Agregar tarea"),
                  ]),
                  _c(
                    VCardText,
                    {
                      staticClass: "mt-5",
                      staticStyle: { "max-width": "600px", margin: "0 auto" },
                    },
                    [
                      _c(
                        VRow,
                        { staticClass: "mt-2" },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "tarea",
                                  name: "tarea",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.tasks,
                                  label: "Tarea*",
                                  autocomplete: "off",
                                  disabled: _vm.isViewModal(),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.taskChangeCombo($event)
                                  },
                                },
                                model: {
                                  value: _vm.asignatedTaskItem.task,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.asignatedTaskItem, "task", $$v)
                                  },
                                  expression: "asignatedTaskItem.task",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("tarea"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "legalizacion",
                                  label: "Legalización*",
                                  name: "legalizacion",
                                  disabled: _vm.isViewModal(),
                                },
                                model: {
                                  value: _vm.asignatedTaskItem.legalizacion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "legalizacion",
                                      $$v
                                    )
                                  },
                                  expression: "asignatedTaskItem.legalizacion",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("legalizacion"))
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "apostilla",
                                  label: "Apostilla*",
                                  name: "apostilla",
                                  disabled: _vm.isViewModal(),
                                },
                                model: {
                                  value: _vm.asignatedTaskItem.apostilla,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "apostilla",
                                      $$v
                                    )
                                  },
                                  expression: "asignatedTaskItem.apostilla",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("apostilla"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "apostilla_urgente",
                                  label: "Apostilla Urgente*",
                                  name: "apostilla_urgente",
                                  disabled: _vm.isViewModal(),
                                },
                                model: {
                                  value:
                                    _vm.asignatedTaskItem.apostilla_urgente,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "apostilla_urgente",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "asignatedTaskItem.apostilla_urgente",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(
                                  _vm._s(_vm.errors.first("apostilla_urgente"))
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c(VTextField, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "anexo",
                                  label: "Anexo*",
                                  name: "anexo",
                                  disabled: _vm.isViewModal(),
                                },
                                model: {
                                  value: _vm.asignatedTaskItem.anexo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "anexo",
                                      $$v
                                    )
                                  },
                                  expression: "asignatedTaskItem.anexo",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("anexo"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(VCombobox, {
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "cargo",
                                  name: "cargo",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.cargos,
                                  label: "Cargo",
                                  autocomplete: "off",
                                  disabled: _vm.isViewModal(),
                                },
                                model: {
                                  value: _vm.asignatedTaskItem.cargo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "cargo",
                                      $$v
                                    )
                                  },
                                  expression: "asignatedTaskItem.cargo",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("cargo"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _vm._v(" " + _vm._s(/*ONLY EMPLOYEES*/) + " "),
                              _c(VCombobox, {
                                attrs: {
                                  dense: "",
                                  "data-vv-as": "notary",
                                  name: "notary",
                                  "item-text": "name",
                                  "item-value": "id",
                                  items: _vm.notaryes,
                                  label: "Escribano",
                                  autocomplete: "off",
                                  disabled: _vm.isViewModal(),
                                },
                                model: {
                                  value: _vm.asignatedTaskItem.notary,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "notary",
                                      $$v
                                    )
                                  },
                                  expression: "asignatedTaskItem.notary",
                                },
                              }),
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(_vm._s(_vm.errors.first("notary"))),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("hr", { staticClass: "mt-4" }),
                          _c(
                            VRow,
                            [
                              _c(VCol, { attrs: { md: "12" } }, [
                                _c(
                                  "p",
                                  { staticClass: "mt-5 mb-5 fs-16 float-left" },
                                  [
                                    _c("b", [_vm._v("Actas / Libros / Fojas")]),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "ml-5 text-white",
                                        attrs: {
                                          small: "",
                                          color: "blue-grey",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addNrDetail()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: { big: "", color: "green" },
                                          },
                                          [_vm._v(" add ")]
                                        ),
                                        _c("span", [_vm._v("1 Acta")]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "ml-3 text-white",
                                        attrs: {
                                          small: "",
                                          color: "blue-grey",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openQuickLoad()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: { big: "", color: "orange" },
                                          },
                                          [_vm._v(" bolt ")]
                                        ),
                                        _c("span", [_vm._v("Varias actas")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(VDataTable, {
                            staticClass: "pb-4",
                            staticStyle: { border: "solid 1px #1c1c1c" },
                            attrs: {
                              headers: _vm.header_details,
                              items: this.asignatedTaskItem.details,
                              "hide-default-footer": true,
                              "items-per-page": 100,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c(VTextField, {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "decimal",
                                                expression: "'decimal'",
                                              },
                                            ],
                                            staticClass: "mt-3",
                                            attrs: {
                                              autocomplete: "off",
                                              name: "acta",
                                              placeholder: "Nº Acta",
                                              dense: "",
                                              type: "number",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.acta,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "acta",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.acta",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errors.first("acta"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(VTextField, {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "decimal",
                                                expression: "'decimal'",
                                              },
                                            ],
                                            staticClass: "mt-3",
                                            attrs: {
                                              autocomplete: "off",
                                              name: "libro",
                                              placeholder: "Nº Libro",
                                              dense: "",
                                              type: "number",
                                              disabled: _vm.isViewModal(),
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.nextFoja(props.item)
                                              },
                                            },
                                            model: {
                                              value: props.item.libro,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "libro",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.libro",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first("libro")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(VTextField, {
                                            staticClass: "mt-3",
                                            attrs: {
                                              autocomplete: "off",
                                              name: "foja",
                                              placeholder: "Nº Foja",
                                              dense: "",
                                              type: "text",
                                              disabled: _vm.isViewModal(),
                                              loading:
                                                _vm.loading_next_foja[
                                                  props.item.libro
                                                ] || false,
                                            },
                                            model: {
                                              value: props.item.foja,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "foja",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.foja",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errors.first("foja"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(VTextField, {
                                            staticClass: "mt-3",
                                            attrs: {
                                              autocomplete: "off",
                                              name: "anexo",
                                              placeholder: "Anexo",
                                              dense: "",
                                              type: "text",
                                              disabled: _vm.isViewModal(),
                                            },
                                            model: {
                                              value: props.item.anexo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "anexo",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.anexo",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first("anexo")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          !_vm.isViewModal()
                                            ? _c(
                                                VTooltip,
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              VIcon,
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    color:
                                                                      "red",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeDetail(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " indeterminate_check_box "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Remover"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "mt-4" }),
                      _c("div", { staticClass: "row mt-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("label", { staticClass: "fs-16" }, [
                              _vm._v("¿Desea adjuntar documentos?*"),
                            ]),
                            _c(
                              VRadioGroup,
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "¿Desea adjuntar documentos?",
                                  name: "has_attached_files",
                                },
                                model: {
                                  value:
                                    _vm.asignatedTaskItem.has_attached_files,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "has_attached_files",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "asignatedTaskItem.has_attached_files",
                                },
                              },
                              _vm._l(_vm.yesno_group, function (obj) {
                                return _c(VRadio, {
                                  key: obj.id,
                                  attrs: {
                                    label: obj.name,
                                    value: obj.id,
                                    disabled: _vm.isViewModal(),
                                  },
                                })
                              }),
                              1
                            ),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("has_attached_files"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm.asignatedTaskItem.has_attached_files != null
                        ? _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(VTextField, {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "integer|min:0",
                                        expression: "'integer|min:0'",
                                      },
                                    ],
                                    attrs: {
                                      dense: "",
                                      "data-vv-as": "documents_size",
                                      label:
                                        "Indique la cantidad de documentos de la tarea",
                                      name: "documents_size",
                                      disabled: _vm.isViewModal(),
                                    },
                                    model: {
                                      value: _vm.documents_size,
                                      callback: function ($$v) {
                                        _vm.documents_size = $$v
                                      },
                                      expression: "documents_size",
                                    },
                                  }),
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("documents_size"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asignatedTaskItem.has_attached_files != null
                        ? _c(
                            "div",
                            [
                              _c("hr", { staticClass: "mt-4" }),
                              _c(
                                VRow,
                                [
                                  _c(VCol, { attrs: { md: "12" } }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mt-5 mb-5 fs-16 float-left",
                                      },
                                      [_c("b", [_vm._v("Documentos")])]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(VDataTable, {
                                staticClass: "pb-4",
                                staticStyle: { border: "solid 1px #1c1c1c" },
                                attrs: {
                                  headers:
                                    _vm.asignatedTaskItem.task != null &&
                                    _vm.asignatedTaskItem.task.criteria_price
                                      .id == "H"
                                      ? _vm.headers_documents_w_pages
                                      : _vm.headers_documents_w_signatures,
                                  items: this.asignatedTaskItem.documents,
                                  "hide-default-footer": true,
                                  "items-per-page": 100,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              staticStyle: { height: "100px" },
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(props.index + 1) +
                                                    " "
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm.asignatedTaskItem
                                                  .has_attached_files != null &&
                                                _vm.asignatedTaskItem
                                                  .has_attached_files === true
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(VFileInput, {
                                                          directives: [
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          key: props.index,
                                                          attrs: {
                                                            dense: "",
                                                            "truncate-length":
                                                              "15",
                                                            name: "document",
                                                            accept:
                                                              "application/pdf",
                                                            label:
                                                              "Seleccione el documento",
                                                            disabled:
                                                              _vm.isViewModal(),
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDocumentsChange(
                                                                props.index
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              props.item
                                                                .file_attached,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.item,
                                                                "file_attached",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.item.file_attached",
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "red--text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "document"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.item.name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]),
                                              _vm.asignatedTaskItem.task !=
                                                null &&
                                              _vm.asignatedTaskItem.task
                                                .criteria_price.id == "H"
                                                ? _c(
                                                    "td",
                                                    [
                                                      _c(VTextField, {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "decimal",
                                                            expression:
                                                              "'decimal'",
                                                          },
                                                        ],
                                                        staticClass: "mt-3",
                                                        attrs: {
                                                          autocomplete: "off",
                                                          name: "pages",
                                                          dense: "",
                                                          type: "number",
                                                          disabled:
                                                            _vm.isViewModal(),
                                                        },
                                                        model: {
                                                          value:
                                                            props.item.pages,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              props.item,
                                                              "pages",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "props.item.pages",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "red--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "pages"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  !_vm.isViewModal()
                                                    ? _c(
                                                        VTooltip,
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      VIcon,
                                                                      _vm._g(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              color:
                                                                                "red",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeDocument(
                                                                                  props.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " indeterminate_check_box "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v("Remover"),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1516930865
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asignatedTaskItem.task != null &&
                      _vm.asignatedTaskItem.task.has_signatories
                        ? _c(
                            "div",
                            [
                              _c("hr", { staticClass: "mt-4" }),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mt-5 mb-5 fs-16 float-left",
                                        },
                                        [_c("b", [_vm._v("Firmantes")])]
                                      ),
                                      !_vm.isViewModal()
                                        ? _c(
                                            VBtn,
                                            {
                                              staticClass: "mt-4 ml-3",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialog_add_signatory = true
                                                },
                                              },
                                            },
                                            [_vm._v("+ Agregar firmante")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(VDataTable, {
                                staticClass: "pb-4",
                                staticStyle: { border: "solid 1px #1c1c1c" },
                                attrs: {
                                  headers: _vm.headers_signatoryes,
                                  items: _vm.asignatedTaskItem.signatories,
                                  "hide-default-footer": true,
                                  "items-per-page": 100,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (props) {
                                        return [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(props.index + 1) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(props.item.name)),
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(props.item.dni)),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                !_vm.isViewModal()
                                                  ? _c(
                                                      VTooltip,
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    VIcon,
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "red",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeSignatory(
                                                                                props.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " indeterminate_check_box "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Remover"),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  423349311
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("hr", { staticClass: "pt-4" }),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("label", { staticClass: "fs-16" }, [
                              _c("b", [_vm._v("Prioridad")]),
                            ]),
                            _c("p", { staticClass: "subtext" }, [
                              _vm._v(
                                "Marque la prioridad que requiere la tarea"
                              ),
                            ]),
                            _c(
                              VRadioGroup,
                              {
                                model: {
                                  value: _vm.asignatedTaskItem.priority,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.asignatedTaskItem,
                                      "priority",
                                      $$v
                                    )
                                  },
                                  expression: "asignatedTaskItem.priority",
                                },
                              },
                              _vm._l(_vm.priority_group, function (obj) {
                                return _c(VRadio, {
                                  key: obj.id,
                                  attrs: {
                                    label: obj.name,
                                    value: obj.id,
                                    disabled: _vm.isViewModal(),
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _c(
                            VBtn,
                            {
                              attrs: { color: "error", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeModalTask()
                                },
                              },
                            },
                            [_vm._v("Cerrar")]
                          ),
                          !_vm.isViewModal()
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    color: "success",
                                    loading: _vm.loading,
                                    text: "",
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.asignatedTaskItemEdit == null
                                        ? "Agregar"
                                        : "Editar"
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
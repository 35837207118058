import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    [
      _c(
        VDialog,
        {
          attrs: { value: _vm.dialog, "max-width": "850px" },
          on: {
            "click:outside": function ($event) {
              return _vm.close()
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.close()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    VBtn,
                    _vm._g(
                      {
                        staticClass: "mb-5",
                        attrs: { color: "primary", dark: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("updateDialog", true)
                          },
                        },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.button_name))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.createItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(VCardTitle, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.modal_title)),
                    ]),
                  ]),
                  _c(VCardText, { staticClass: "modal-content" }, [
                    _c("div", { staticClass: "section-container" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm.property_sale.id > 0
                          ? _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c(VTextField, {
                                  attrs: {
                                    disabled: true,
                                    label: "Carpeta",
                                    name: "id",
                                  },
                                  model: {
                                    value: _vm.property_sale.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.property_sale, "id", $$v)
                                    },
                                    expression: "property_sale.id",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            class:
                              _vm.property_sale.id > 0
                                ? "col-md-3"
                                : "col-md-4",
                          },
                          [
                            _c("DatePickerEsp", {
                              attrs: {
                                initToday: true,
                                label: "Fecha de apertura",
                                date: _vm.property_sale.open_date,
                                isRequired: true,
                                name: "open_date",
                                isViewModal: _vm.isViewModal(),
                              },
                              on: {
                                setDate: function (date) {
                                  _vm.property_sale.open_date = date
                                },
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("open_date"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            class:
                              _vm.property_sale.id > 0
                                ? "col-md-3"
                                : "col-md-4",
                          },
                          [
                            _c(VCombobox, {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                "data-vv-as": "notary",
                                label: "Escribano*",
                                name: "notary",
                                items: _vm.employees_notary,
                                "item-text": "name",
                                "item-value": "id",
                                disabled: _vm.isViewModal(),
                                loading: _vm.loading_notary,
                              },
                              model: {
                                value: _vm.property_sale.notary,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property_sale, "notary", $$v)
                                },
                                expression: "property_sale.notary",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("notary"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            class:
                              _vm.property_sale.id > 0
                                ? "col-md-3"
                                : "col-md-4",
                          },
                          [
                            _c(VCombobox, {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                "data-vv-as": "folder_manager",
                                label: "Carpetista*",
                                name: "folder_manager",
                                items: _vm.employees_employee,
                                "item-text": "name",
                                "item-value": "id",
                                disabled: _vm.isViewModal(),
                                loading: _vm.loading_folder_manager,
                              },
                              model: {
                                value: _vm.property_sale.folder_manager,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "folder_manager",
                                    $$v
                                  )
                                },
                                expression: "property_sale.folder_manager",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("folder_manager"))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c(VCombobox, {
                              attrs: {
                                "data-vv-as": "prepare",
                                label: "Preparo",
                                name: "prepare",
                                items: _vm.employees,
                                "item-text": "name",
                                "item-value": "id",
                                disabled: _vm.isViewModal(),
                                loading: _vm.loading_employee,
                              },
                              model: {
                                value: _vm.property_sale.prepare,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property_sale, "prepare", $$v)
                                },
                                expression: "property_sale.prepare",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c(VCombobox, {
                              attrs: {
                                "data-vv-as": "realize",
                                label: "Reviso",
                                name: "realize",
                                items: _vm.employees,
                                "item-text": "name",
                                "item-value": "id",
                                disabled: _vm.isViewModal(),
                                loading: _vm.loading_employee,
                              },
                              model: {
                                value: _vm.property_sale.realize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property_sale, "realize", $$v)
                                },
                                expression: "property_sale.realize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _c(VCombobox, {
                              attrs: {
                                "data-vv-as": "armario",
                                label: "Armario",
                                name: "cupboard_row",
                                items: _vm.cupboards_rows,
                                "item-text": "name",
                                "item-value": "id",
                                disabled: _vm.isViewModal(),
                                loading: _vm.loading_cupboard,
                              },
                              model: {
                                value: _vm.property_sale.cupboard_row,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "cupboard_row",
                                    $$v
                                  )
                                },
                                expression: "property_sale.cupboard_row",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("cupboard_row"))),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "flex-grow-1",
                                attrs: {
                                  "data-vv-as": "compradores",
                                  label: "Seleccione los compradores*",
                                  name: "property_buyers",
                                  items: _vm.clients,
                                  "item-text": "business_name",
                                  "item-value": "id",
                                  disabled: _vm.isViewModal(),
                                  multiple: "",
                                  loading: _vm.loading_client,
                                  chips: "",
                                  "small-chips": "",
                                },
                                model: {
                                  value: _vm.property_sale.property_buyers,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.property_sale,
                                      "property_buyers",
                                      $$v
                                    )
                                  },
                                  expression: "property_sale.property_buyers",
                                },
                              }),
                              !_vm.isViewModal()
                                ? _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  VIcon,
                                                  _vm._g(
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        border:
                                                          "1px solid #2196f3",
                                                        "border-radius": "50%",
                                                        padding: "5px",
                                                      },
                                                      attrs: { color: "blue" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addClient(
                                                            "buyers"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-plus ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3262126449
                                      ),
                                    },
                                    [_c("span", [_vm._v("Crear cliente")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(_vm._s(_vm.errors.first("property_buyers"))),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(VCombobox, {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  "data-vv-as": "vendedores",
                                  label: "Seleccione los vendedores*",
                                  name: "property_sellers",
                                  items: _vm.clients,
                                  "item-text": "business_name",
                                  "item-value": "id",
                                  disabled: _vm.isViewModal(),
                                  multiple: "",
                                  loading: _vm.loading_client,
                                  chips: "",
                                  "small-chips": "",
                                },
                                model: {
                                  value: _vm.property_sale.property_sellers,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.property_sale,
                                      "property_sellers",
                                      $$v
                                    )
                                  },
                                  expression: "property_sale.property_sellers",
                                },
                              }),
                              !_vm.isViewModal()
                                ? _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  VIcon,
                                                  _vm._g(
                                                    {
                                                      staticClass: "ml-2",
                                                      staticStyle: {
                                                        border:
                                                          "1px solid #2196f3",
                                                        "border-radius": "50%",
                                                        padding: "5px",
                                                      },
                                                      attrs: { color: "blue" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addClient(
                                                            "sellers"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-plus ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3215072425
                                      ),
                                    },
                                    [_c("span", [_vm._v("Crear cliente")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "red--text" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first("property_sellers"))
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-9" },
                          [
                            _c(VTextField, {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                "data-vv-as": "direccion",
                                label: "Dirección del inmueble*",
                                name: "address",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property_sale, "address", $$v)
                                },
                                expression: "property_sale.address",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("address"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(VTextField, {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|decimal:2",
                                  expression: "'required|decimal:2'",
                                },
                              ],
                              attrs: {
                                "data-vv-as": "importe",
                                label: "Importe*",
                                name: "amount",
                                type: "number",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.amount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property_sale, "amount", $$v)
                                },
                                expression: "property_sale.amount",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("amount"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(VTextarea, {
                              attrs: {
                                "data-vv-as": "observacion",
                                label: "Observación",
                                name: "observation",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.observation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "observation",
                                    $$v
                                  )
                                },
                                expression: "property_sale.observation",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("observation"))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(VCombobox, {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                "data-vv-as": "operation",
                                label: "Operación*",
                                name: "operation",
                                items: _vm.operations,
                                "item-text": "name",
                                "item-value": "id",
                                disabled: _vm.isViewModal(),
                                loading: _vm.loading_operation,
                              },
                              model: {
                                value: _vm.property_sale.operation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.property_sale, "operation", $$v)
                                },
                                expression: "property_sale.operation",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(_vm._s(_vm.errors.first("operation"))),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "section-container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("p", { staticClass: "title-modal" }, [
                            _vm._v("Firma"),
                          ]),
                          _c("hr"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("DatePickerEsp", {
                              attrs: {
                                "data-vv-as": "fecha",
                                label: "Fecha",
                                date: _vm.property_sale.signature_date,
                                isRequired: true,
                                name: "signature_date",
                                isViewModal: _vm.isViewModal(),
                              },
                              on: {
                                setDate: function (date) {
                                  _vm.property_sale.signature_date = date
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c(
                              VMenu,
                              {
                                ref: "menu",
                                attrs: {
                                  "close-on-content-click": false,
                                  "nudge-right": 40,
                                  "return-value":
                                    _vm.property_sale.signature_time,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "max-width": "290px",
                                  "min-width": "290px",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    return _vm.$set(
                                      _vm.property_sale,
                                      "signature_time",
                                      $event
                                    )
                                  },
                                  "update:return-value": function ($event) {
                                    return _vm.$set(
                                      _vm.property_sale,
                                      "signature_time",
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          VTextField,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Hora de inicio",
                                                  "prepend-icon":
                                                    "mdi-clock-time-four-outline",
                                                  readonly: "",
                                                  name: "time",
                                                  disabled: _vm.isViewModal(),
                                                },
                                                model: {
                                                  value:
                                                    _vm.property_sale
                                                      .signature_time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.property_sale,
                                                      "signature_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "property_sale.signature_time",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.menu_time,
                                  callback: function ($$v) {
                                    _vm.menu_time = $$v
                                  },
                                  expression: "menu_time",
                                },
                              },
                              [
                                _vm.menu_time
                                  ? _c(VTimePicker, {
                                      attrs: { "full-width": "" },
                                      on: {
                                        "click:minute": function ($event) {
                                          return _vm.$refs.menu.save(
                                            _vm.property_sale.signature_time
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.property_sale.signature_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.property_sale,
                                            "signature_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "property_sale.signature_time",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(VTextField, {
                              attrs: {
                                "data-vv-as": "lugar de firma",
                                label: "Lugar de firma",
                                name: "signature_location",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.signature_location,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "signature_location",
                                    $$v
                                  )
                                },
                                expression: "property_sale.signature_location",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-md-12 d-flex justify-content-end",
                          },
                          [
                            _c(
                              VBtn,
                              {
                                attrs: {
                                  color: "primary",
                                  small: "",
                                  disabled:
                                    _vm.property_sale.signature_time == "" ||
                                    _vm.property_sale.signature_date == "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "calendar",
                                      _vm.property_sale
                                    )
                                  },
                                },
                              },
                              [_vm._v("Agendar evento")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "section-container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("p", { staticClass: "title-modal" }, [
                            _vm._v("Escriturado"),
                          ]),
                          _c("hr"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [_c(VLabel, [_vm._v("Venta")])],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(VTextField, {
                              attrs: {
                                "data-vv-as": "folio",
                                label: "Folio",
                                name: "written_sale_folio",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.written_sale_folio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "written_sale_folio",
                                    $$v
                                  )
                                },
                                expression: "property_sale.written_sale_folio",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("written_sale_folio"))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(VTextField, {
                              attrs: {
                                "data-vv-as": "numero",
                                label: "Número",
                                name: "written_sale_numero",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.written_sale_numero,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "written_sale_numero",
                                    $$v
                                  )
                                },
                                expression: "property_sale.written_sale_numero",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                _vm._s(_vm.errors.first("written_sale_numero"))
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [_c(VLabel, [_vm._v("Hipoteca")])],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(VTextField, {
                              attrs: {
                                "data-vv-as": "folio",
                                label: "Folio",
                                name: "written_mortage_folio",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.written_mortage_folio,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "written_mortage_folio",
                                    $$v
                                  )
                                },
                                expression:
                                  "property_sale.written_mortage_folio",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.errors.first("written_mortage_folio")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(VTextField, {
                              attrs: {
                                "data-vv-as": "numero",
                                label: "Número",
                                name: "written_mortage_numero",
                                disabled: _vm.isViewModal(),
                              },
                              model: {
                                value: _vm.property_sale.written_mortage_numero,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.property_sale,
                                    "written_mortage_numero",
                                    $$v
                                  )
                                },
                                expression:
                                  "property_sale.written_mortage_numero",
                              },
                            }),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.errors.first("written_mortage_numero")
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "section-container" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c("p", { staticClass: "title-modal" }, [
                            _vm._v("Documentacion"),
                          ]),
                          _c("hr"),
                          _c("ListPropertyDocumentation", {
                            attrs: {
                              list: _vm.property_sale.property_documents,
                              loading: _vm.loading_documentation,
                              isViewModal: _vm.isViewModal(),
                            },
                            on: { updateDocument: _vm.updateDocument },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "section-container" },
                      [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("p", { staticClass: "title-modal" }, [
                            _vm._v("Liberacion"),
                          ]),
                          _c("hr"),
                        ]),
                        _c("ListPropertyReleases", {
                          attrs: {
                            list: _vm.property_sale.property_releases,
                            loading: _vm.loading_releases,
                            isViewModal: _vm.isViewModal(),
                          },
                          on: { updateRelease: _vm.updateRelease },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    VCardActions,
                    {
                      staticStyle: { "background-color": "rgb(221, 225, 230)" },
                    },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "error", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v("Cerrar")]
                      ),
                      !_vm.isViewModal()
                        ? _c(
                            VBtn,
                            {
                              attrs: {
                                color: "success",
                                text: "",
                                type: "submit",
                                loading: _vm.loading,
                              },
                            },
                            [_vm._v(_vm._s(_vm.formBtn))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ModalClient", {
            attrs: {
              modal_title: _vm.modal_title_client,
              formBtn: _vm.formBtn,
              dialog: _vm.dialog_client,
              parent: _vm.parent,
              service: _vm.generalService,
              item: _vm.mainItemClient,
              section: _vm.Constant.SECTION_CLIENTS,
            },
            on: {
              updateDialog: _vm.updateDialogClient,
              loadList: function ($event) {
                return _vm.loadClients(true, _vm.type_add)
              },
              updateItem: function (newItem) {
                _vm.mainItemClient = newItem
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { wsService } from "./wsService";

const section = "property_sales";

  const MISSING_DOCUMENT = 0;
  const PENDING_SIGNATURE = 1;
  const PENDING_RELEASE = 2;
  const FINISHED = 3;
  const SIGNED = 4;

function filter(param) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function getPropertyDocuments(param = {}) {
  return wsService.make_get_request(section + "/property_documents", param);
}
function getPropertyReleases(param = {}) {
  return wsService.make_get_request(section + "/property_releases", param);
}

function states(param) {
  return wsService.make_post_request(section + "/states", param);
}

function operations(param) {
  return wsService.make_post_request(section + "/operations", param);
}

function getStateHtml(state) {
  switch (parseInt(state.id)) {
    case MISSING_DOCUMENT:
      return '<p class="state_pending">' + state.name + "</p>";
    case PENDING_SIGNATURE:
      return '<p class="state_running">' + state.name + "</p>";
    case PENDING_RELEASE:
      return '<p class="state_accounting_unbilled">' + state.name + "</p>";
    case SIGNED:
      return '<p class="state_accounting_canceled">' + state.name + "</p>";
    case FINISHED:
      return '<p class="state_finish">' + state.name + "</p>";
    default:
      return "-";
  }
}

function printPDF(property_sale_id) {
  return wsService.make_post_request(
    section + "/print/" + property_sale_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

export const propertySaleService = {
  section,  
  filter,
  remove,
  create,
  update,
  getPropertyDocuments,
  getPropertyReleases,
  getStateHtml,
  printPDF,
  states,
  operations
};
